import "components/shared/error/Error.scss";

import { Alert, Container } from "react-bootstrap";
import React, { useEffect, useRef } from "react";
import { isNullOrUndefOrEmpty, isString, useAutoScrollRef } from "@civicscience/chops";

import { CSErrorAlertStatus } from "types";
import classNames from "classnames";

type ErrorAlertProps = CSErrorAlertStatus;

const Error = ({
  errorMessage,
  children,
  errorHeaderMessage,
  className,
  fluid = "xl",
  showIcon = true,
  onDismiss,
  autoScroll = true,
  scrollDelay = 300,
  ...alertProps
}: ErrorAlertProps): JSX.Element | null => {
  //Dont show Error alert if errorMessage, children and errorHeaderMessage are all null.
  if (
    isNullOrUndefOrEmpty(errorMessage) &&
    isNullOrUndefOrEmpty(children) &&
    isNullOrUndefOrEmpty(errorHeaderMessage)
  ) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const alertRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useAutoScrollRef(useEffect, alertRef, autoScroll, scrollDelay);

  const hasErrorMessage = !isNullOrUndefOrEmpty(errorMessage);
  const hasChildren = !isNullOrUndefOrEmpty(children);
  const hasErrorHeaderMessage = isString(errorHeaderMessage) && !isNullOrUndefOrEmpty(errorHeaderMessage);
  const errorHeaderMessageString = isString(errorHeaderMessage) ? errorHeaderMessage : "Error!";

  const errorPaddingClass = isNullOrUndefOrEmpty(fluid) ? "cs-error-not-fluid" : "px-0";

  const renderErrorIcon = (iconMessage: string) => {
    return showIcon ? (
      <i className={`cs-error-i csa-i fas fa-exclamation-circle text-danger`} aria-hidden={true} title={iconMessage} />
    ) : null;
  };

  return (
    <Alert
      {...alertProps}
      variant="danger"
      className={classNames("cs-error rounded-0", errorPaddingClass, className)}
      aria-label={errorHeaderMessageString}
      dismissible={!isNullOrUndefOrEmpty(onDismiss)}
      onClose={onDismiss}
      ref={alertRef}
      tabIndex={-1}
    >
      <Container
        className={classNames("cs-error-detail", { "d-flex": !hasErrorHeaderMessage && showIcon })}
        fluid={fluid}
      >
        {hasErrorHeaderMessage ? (
          <div className="cs-error-hdr d-flex align-items-center">
            {renderErrorIcon(errorHeaderMessageString)}
            <span className="h6 ms-0 my-auto" role="heading" aria-level={2}>
              {errorHeaderMessageString}
            </span>
          </div>
        ) : (
          renderErrorIcon(errorHeaderMessageString)
        )}

        {hasErrorMessage && (
          <div className={classNames("alert-heading cs-error-msg", { "ps-4": hasErrorHeaderMessage })}>
            {isString(errorMessage) ? (
              errorMessage
            ) : (
              <pre
                className={classNames("cs-error-msg-pre text-danger", {
                  "ps-4": hasErrorHeaderMessage,
                  "mt-auto": !hasErrorHeaderMessage,
                })}
              >
                {errorMessage.toString()}
              </pre>
            )}
          </div>
        )}

        {hasChildren && <div className="cs-error-block">{children}</div>}
      </Container>
    </Alert>
  );
};

export default Error;
