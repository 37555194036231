import API from "api/client";
import { DateFormats } from "consts";
import { envConfig } from "config";
import { format } from "date-fns";
import { normalizeEndDate } from "utils";

/**
 * @typedef { import('types').QuestionFull } QuestionFull
 * @typedef { import('types').QuestionEditRequest } QuestionEditRequest
 * @typedef { import('types').QuestionSearchResult } QuestionSearchResult
 * @typedef { import('types').SearchQuestionsResponse } SearchQuestionsResponse
 * @typedef { import("types/questions").GetQuestionCompareTableResultsArgs } GetQuestionCompareTableResultsArgs
 * @typedef { import("types/questions").GetQuestionCompareCheckboxResultsArgs } GetQuestionCompareCheckboxResultsArgs
 * @typedef { import("types/questions").QuestionCompareCrosstabResponse } QuestionCompareCrosstabResponse
 * @typedef { import("types/questions").CheckboxQuestionCompareCrosstabResponse } CheckboxQuestionCompareCrosstabResponse
 * @typedef { import("types/questions").QuestionAnswerChoice } QuestionAnswerChoice
 * @typedef { import("types/questions").QuestionAnswerGrouping } QuestionAnswerGrouping
 * @typedef { import("types/questions").QuestionDisplayOption } QuestionDisplayOption
 * @typedef { import("types/questions").QuestionInsightListResponse } QuestionInsightListResponse
 * @typedef { import('types').QuestionSearchArgs } QuestionSearchArgs
 * @typedef { import('types').QuestionSearchCorrelatedArgs } QuestionSearchCorrelatedArgs
 * @typedef { import("types/questions").DisplayOptionsForQuestion } DisplayOptionsForQuestion
 * @typedef { import("types/questions").Tag } CivicScienceTag
 * @typedef { import("types/questions").TaxonomyTag } QuestionTaxonomyTag
 *
 * @typedef { import("types").PagedApiResponse } PagedApiResponse
 */

const { questionsAPI } = envConfig;

/**
 * @param {Object} request - The values being updated.
 * @param {string} request.qtext - The question's text.
 * @param {string} request.qtype - The type of the question.
 * @param {int} request.accountRefid - The account id the question is associated with.
 * @param {bool} request.random - Indicates whether the question answers are randomized.
 * @param {bool} request.isGlobalNetworkApproved - Indicates if the question is globally approved.
 * @param {bool} request.hideResults - Indicates whether results should be hidden for the question.
 * @param {string} request.locale - The locale code for the question.
 * @param {string} request.weightingSchemeName - The weighting scheme of the question.
 * @param {string} request.subType - The subtype of the question.
 * @param {int} request.repeatIneligibleSeconds - The repeat value for the question.
 * @param {string} request.topic - The topic associated with the question.
 * @param {bool} request.isSponsored - Indicates if the question is sponsored or not.
 * @param {bool} request.hasRoleEngagement - Indicates whether a question is type engagement.
 * @param {bool} request.hasRoleValue - Indicates whether a question is type value.
 * @param {bool} request.hasRoleProfile - Indicates whether a question is type profile.
 * @param {Array} request.answers - List of answer choice texts for the question.
 * @param {string} request.quizAnswer - The optional answer for question of quiz type.
 * @param {string} request.explanationText - The optional explanation text for the question.
 * @param {string} request.explanationTextTitle - The optional explanation text title for the question.
 * @returns {Promise<{}>}
 */
const createQuestion = (request) => API.post(`${questionsAPI}`, request).then((res) => res.data);

/**
 * @typedef {Object[]} Labels
 * @property {str} label - Label name of combined answer choices
 * @property {int[]} vuris - List of answer choice id's
 */

/**
 *
 * @param {number|string} questionId
 * @param {Object} req
 * @param {str} req.label - The name of the groupingLabel
 * @param {bool} req.isPublic - Is the answer grouping public
 * @param {Labels} req.labels - list representing each label and vuris
 * @returns {Promise<T>}
 */
const createQuestionAnswerGrouping = (questionId, req) =>
  API.post(`${questionsAPI}${questionId}/answer-groupings`, req).then((res) => res.data);

/**
 *
 * @param id {int|string}
 * @param comment {Object}
 * @returns {Promise<T>}
 */
const createQuestionComment = (id, comment) =>
  API.post(`${questionsAPI}${id}/comments`, comment).then((res) => res.data);

/**
 *
 * @param id {int|string}
 * @param tagId {int|string}
 * @returns {Promise<T>}
 */
const createTagToQuestion = (id, tagId) => API.post(`${questionsAPI}${id}/tags/${tagId}`).then((res) => res.data);

/**
 *
 * @param id {int|string}
 * @param tagId {int|string}
 * @returns {Promise<T>}
 */
const deleteTagToQuestion = (id, tagId) => API.delete(`${questionsAPI}${id}/tags/${tagId}`).then((res) => res.data);

/**
 *
 * @param id {int|string}
 * @returns {Promise<T>}
 */
const deleteQuestion = (id) => API.delete(`${questionsAPI}${id}`).then((res) => res.data);

/**
 *
 * @param {number|string} questionId
 * @param {number|string} groupId
 * @returns {Promise<T>}
 */
const deleteQuestionAnswerGrouping = (questionId, groupId) =>
  API.delete(`${questionsAPI}${questionId}/answer-groupings/${groupId}`).then((res) => res.data);

/**
 *
 * @param id {int|string}
 * @param qDeploy {Object}
 * @returns {Promise<T>}
 */
const deployQuestion = (id, qDeploy) => API.post(`${questionsAPI}${id}/deploy`, qDeploy).then((res) => res.data);

/**
 *
 * @param {QuestionEditRequest} request
 * @returns {Promise<QuestionFull>}
 */
const editQuestion = async ({ questionId, ...qUpdate }) => {
  return API.patch(`${questionsAPI}${questionId}`, { ...qUpdate, withPermissions: true }).then((res) => res.data);
};

/**
 *
 * @param {number} qid
 * @param {bool} withPermissions - include user permissions for this question
 * @returns {Promise<QuestionFull>}
 */
const getQuestion = (qid, withPermissions) =>
  API.get(`${questionsAPI}${qid}`, { params: { withPermissions } }).then((res) => res.data);

/**
 *
 * @param {number|string} qid
 * @returns {Promise<QuestionAnswerChoice[]>}
 */
const getQuestionAnswerChoices = (qid) => API.get(`${questionsAPI}${qid}/choices`).then((res) => res.data);

/**
 *
 * @param {number|string} questionId
 * @param {number|string} groupId
 * @returns {Promise<T>}
 */
const getQuestionAnswerGrouping = (questionId, groupId) =>
  API.get(`${questionsAPI}${questionId}/answer-groupings/${groupId}`).then((res) => res.data);

/**
 *
 * @param {number|string} questionId
 * @param {object} [params] - Holds pagination data
 * @param {number|string } [params.page] - Current page
 * @param {number|string} [params.size] - List size
 * @returns {Promise<PagedApiResponse<QuestionAnswerGrouping[]>>}
 */
const getQuestionAnswerGroupings = (questionId, params = { page: 1, size: 10 }) =>
  API.get(`${questionsAPI}${questionId}/answer-groupings`, { params }).then((res) => res.data);

/**
 *
 * @param {number|string} questionId
 * @returns {Promise<DisplayOptionsForQuestion>}
 */
const getQuestionDisplayOptions = (questionId) =>
  API.get(`${questionsAPI}${questionId}/display-options`).then((res) => res.data);

/**
 * Fetches all display groupings for a question.
 * This is both the user defined groupings as well as "dynamic" groupings generated by the system.
 * @param {number|string} questionId
 * @returns {Promise<QuestionDisplayOption[]>}
 */
const getQuestionDisplayGroupings = (questionId) =>
  API.get(`${questionsAPI}${questionId}/display-groupings`).then((res) => res.data);

/**
 *
 * @param questionId {number|string}
 * @returns {Promise<QuestionDisplayScoresData[]>}
 */
const getQuestionDisplayScores = (questionId) =>
  API.get(`${questionsAPI}${questionId}/display-scores`).then((res) => res.data);

/**
 *
 * @param id {int|string}
 * @returns {Promise<T>}
 */
const getQuestionComments = (id) => API.get(`${questionsAPI}${id}/comments`).then((res) => res.data);

/**
 *
 * @param id {int|string}
 * @returns {Promise<T>}
 */
const getQuestionScores = (id) => API.get(`${questionsAPI}${id}/scores`).then((res) => res.data);

/**
 *
 * @param id {int|string}
 * @returns {Promise<T>}
 */
const getQuestionNotifications = (id) => API.get(`${questionsAPI}${id}/notifications`).then((res) => res.data);

/**
 *
 * @param id {int|string}
 * @returns {Promise<T>}
 */
const getQuestionPins = (id) => API.get(`${questionsAPI}${id}/pins`).then((res) => res.data);

const stopQuestionPin = (id, pin) => API.put(`${questionsAPI}${id}/stop-pin`, pin).then((res) => res.data);

/**
 *
 * @param id {int|string}
 * @returns {Promise<T>}
 */
const getQuestionSamples = (id) => API.get(`${questionsAPI}${id}/question-samples`).then((res) => res.data);

/**
 *
 * @param id {int|string}
 * @returns {Promise<T>}
 */
const getQuestionTargets = (id) => API.get(`${questionsAPI}${id}/targets`).then((res) => res.data);

/**
 * @typedef GetQuestionResultsArgs
 * @param {object} opts
 * @param {number|string} opts.questionId - the id of the question
 * @param {number|string} [opts.targetId] - target id to filter results by
 * @param {string} [opts.weightingSchemeName] - weighting scheme name to weight results
 * @param {string} [opts.segmentUUID] - segment uuid to filter results by
 * @param {string} [opts.startDate] - start date of results to fetch
 * @param {string} [opts.endDate] - end date of results to fetch
 */

/**
 *
 * @param {GetQuestionResultsArgs} options
 * @returns {Promise<T>}
 */
const getQuestionResults = ({ questionId, targetId, weightingSchemeName, segmentUUID, startDate, endDate, network }) =>
  API.get(`${questionsAPI}${questionId}/c-results`, {
    params: {
      targetId,
      wscheme: weightingSchemeName,
      segment: segmentUUID,
      network,
      startDate,
      endDate: normalizeEndDate(startDate, endDate),
    },
  }).then((res) => res.data);

/**
 * Fetches data comparing 2 questions and returns results in table format.
 *
 *
 * @param {GetQuestionCompareTableResultsArgs} params
 * @returns {Promise<QuestionCompareCrosstabResponse>}
 */
const getQuestionCompareTableResults = ({
  qid,
  cmpQid,
  answerIdsMainQuestion,
  answerIdsComparisonQuestion,
  valueGroupingUri,
  secondaryValueGroupingUri,
  scoreUri,
  targetId,
  network,
  weightingSchemeName,
  segments,
  startDate,
  endDate,
}) =>
  API.get(`${questionsAPI}${qid}/c-results/question-compare/table`, {
    params: {
      cmp: cmpQid,
      l_aids: answerIdsMainQuestion,
      r_aids: answerIdsComparisonQuestion,
      lvgUri: valueGroupingUri,
      rvgUri: secondaryValueGroupingUri,
      scoreUri: scoreUri,
      targetId,
      network,
      wscheme: weightingSchemeName,
      segment: segments.filter(Boolean),
      startDate,
      endDate: normalizeEndDate(startDate, endDate),
    },
  }).then((res) => res.data);

/**
 * Fetches data comparing 2 questions and returns results in table format.
 *
 *
 * @param {GetQuestionCompareCheckboxResultsArgs} params
 * @returns {Promise<CheckboxQuestionCompareCrosstabResponse>}
 */
const getQuestionCompareCheckboxResults = ({
  qid,
  checkboxGroupId,
  valueGroupingUri,
  questionAnswerIds,
  scoreUri,
  targetId,
  network,
  weightingSchemeName,
  segments,
  startDate,
  endDate,
}) =>
  API.get(`${questionsAPI}${qid}/c-results/question-compare/checkbox/${checkboxGroupId}/table`, {
    params: {
      question_value_grouping_uri: valueGroupingUri,
      questionAnswerIds,
      scoreUri,
      targetId,
      network,
      wscheme: weightingSchemeName,
      segment: segments.filter(Boolean),
      startDate,
      endDate: normalizeEndDate(startDate, endDate),
    },
  }).then((res) => res.data);

/**
 *
 * @param {object} params - Holds data for url query
 * @param {number|string} params.questionId - the main question id
 * @param {number} params.profileQid - the profile question id
 * @param {number|string} params.targetId - target id to filter results by
 * @param {string} params.weightingSchemeName - weighting scheme name to weight results
 * @param {string} params.segmentUUID - segment uuid to filter results by
 * @param {string} params.network - network code to filter results by
 * @param {string} params.startDate - start date of results to fetch
 * @param {string} params.endDate - end date of results to fetch
 * @param {string|null} params.valueGroupingUri - value grouping to filter results by
 * @returns {Promise<T>}
 */
const getQuestionProfileResults = ({
  questionId,
  profileQid,
  targetId,
  weightingSchemeName,
  segmentUUID,
  network,
  startDate,
  endDate,
  valueGroupingUri,
}) =>
  API.get(`${questionsAPI}${questionId}/c-results/profile`, {
    params: {
      profileId: profileQid,
      targetId,
      wscheme: weightingSchemeName,
      segment: segmentUUID,
      network,
      startDate,
      endDate: normalizeEndDate(startDate, endDate),
      valueGroupingUri,
    },
  }).then((res) => res.data);

/**
 *
 * @param {import('types').MultiScoreResultsArgs} options
 * @returns {Promise<import('types').MultiScoreResults>}
 */
const getQuestionScoreResults = ({
  questionId,
  scoreUri,
  targetId,
  network,
  weightingSchemeName,
  bucketMode,
  segmentUUID,
  startDate,
  endDate,
}) =>
  API.get(`${questionsAPI}${questionId}/c-results/score`, {
    params: {
      scoreUri,
      targetId,
      network,
      wscheme: weightingSchemeName,
      segment: segmentUUID,
      startDate,
      bucketMode,
      endDate: normalizeEndDate(startDate, endDate),
    },
  }).then((res) => res.data);

/**
 * @typedef GetQuestionValueGroupingResultsArgs
 * @param {number|string} questionId - the id of the question
 * @param {number|string} targetId - target id to filter results by
 * @param {string} weightingSchemeName - weighting scheme name to weight results
 * @param {string} segmentUUID - segment uuid to filter results by
 * @param {string} network - network code to filter results by
 * @param {string} startDate - start date of results to fetch
 * @param {string} endDate - end date of results to fetch
 * @param valueGroupingUri {string} [null]
 */

/**
 *
 * @param {GetQuestionValueGroupingResultsArgs} options
 * @returns {Promise<T>}
 */
const getQuestionValueGroupingResults = ({
  questionId,
  targetId,
  weightingSchemeName,
  segmentUUID,
  startDate,
  endDate,
  valueGroupingUri,
  network,
}) =>
  API.get(`${questionsAPI}${questionId}/c-results/value-grouping`, {
    params: {
      valueGroupingUri,
      targetId,
      wscheme: weightingSchemeName,
      segment: segmentUUID,
      network,
      startDate,
      endDate: normalizeEndDate(startDate, endDate),
    },
  }).then((res) => res.data);

/**
 * Fetches all CivicScience tags (does NOT include taxonomy nodes).
 * @param {int|string} id
 * @returns {Promise<CivicScienceTag[]>}
 */
const getQuestionCivicScienceTags = (id) => API.get(`${questionsAPI}${id}/tags`).then((res) => res.data);

/**
 * Fetches all Taxonomy Nodes associated to the provided question ID.
 * This does NOT include CivicScience tags.
 * @param {int|string} id
 * @returns {Promise<QuestionTaxonomyTag[]>}
 */
const getQuestionTaxonomyTags = (id) => API.get(`${questionsAPI}${id}/taxonomy-nodes`).then((res) => res.data);

/**
 * Fetches both CivicScience tags and Taxonomy tags associated to the given question.
 * @param {int|string} id
 * @returns {Promise<{ civicScienceTags: CivicScienceTag[], taxonomyTags: QuestionTaxonomyTag[] }>}
 */
const getQuestionTags = async (id) => {
  const [civicScienceTags, taxonomyTags] = await Promise.all([
    getQuestionCivicScienceTags(id),
    getQuestionTaxonomyTags(id),
  ]);

  return {
    civicScienceTags,
    taxonomyTags,
  };
};

/**
 *
 * @param id {int|string}
 * @returns {Promise<T>}
 */
const getQuestionTranslations = (id) => {
  return API.get(`${questionsAPI}${id}/translations`).then((res) => res.data);
};

/**
 *
 * @param id {int|string}
 * @param translations {Object}
 * @returns {Promise<T>}
 */

const createQuestionTranslations = (id, translations) => {
  return API.post(`${questionsAPI}${id}/translations`, translations).then((res) => res.data);
};

/**
 *
 * @param id {int|string}
 * @param translations {Object}
 * @returns {Promise<T>}
 */

const updateQuestionTranslations = (id, translations) => {
  return API.put(`${questionsAPI}${id}/translations`, translations).then((res) => res.data);
};

const updateQuestionTargetReview = (id, targetReview) => {
  return API.put(`${questionsAPI}${id}/question-target-review`, targetReview).then((res) => res.data);
};

/**
 *
 * @returns {Promise<T>}
 */
const getTrendingQuestions = () => API.get(`${questionsAPI}trending/`).then((res) => res.data);

/**
 * @typedef QuestionScore
 * @property {string} uri
 * @property {string} label
 */

/**
 * @param {QuestionSearchArgs} params
 * @returns {Promise<SearchQuestionsResponse>}
 */
const searchQuestions = ({
  query,
  orderBy,
  order,
  tags,
  taxonomyTags,
  questionType,
  inputType,
  isMyFavoritesOnly,
  responseCountGte,
  isCurrentlyLive,
  isCyclical,
  isTrending,
  isArchived,
  isTracking,
  includeScores,
  searchAnswerOptions,
  searchQuestionText,
  searchTags,
  isEngagement,
  isValue,
  isProfile,
  startDate,
  endDate,
  page = 1,
  size = 10,
}) =>
  API.get(`${questionsAPI}`, {
    params: {
      query,
      orderBy,
      order,
      inputType,
      tags,
      t: taxonomyTags,
      questionType,
      isMyFavoritesOnly,
      isCyclical,
      isTrending,
      isArchived,
      isTracking,
      responseCountGte,
      isCurrentlyLive,
      includeScores,
      searchAnswerOptions,
      searchQuestionText,
      searchTags,
      isEngagement,
      isValue,
      isProfile,
      startDate: startDate ? format(startDate, DateFormats.Y_M_D) : null,
      endDate: endDate ? format(endDate, DateFormats.Y_M_D) : null,
      page,
      size,
    },
  }).then((res) => res.data);

/**
 *
 * @param id {int|string}
 * @param qUpdate {Object}
 * @returns {Promise<T>}
 */
const updateQuestion = (id, qUpdate) => API.put(`${questionsAPI}${id}`, qUpdate).then((res) => res.data);

/**
 * @typedef {Object[]} Labels
 * @property {string} label - Label name of combined answer choices
 * @property {int[]} vuris - List of answer choice id's
 */

/**
 *
 * @param {number|string} questionId
 * @param {number|string} answerGroupingId
 * @param {Object} req
 * @param {string} req.label - The name of the groupingLabel
 * @param {bool} req.isPublic - Is the answer grouping public
 * @param {Labels} req.labels - list representing each label and vuris
 * @returns {Promise<T>}
 */
const updateQuestionAnswerGrouping = (questionId, answerGroupingId, req) =>
  API.put(`${questionsAPI}${questionId}/answer-groupings/${answerGroupingId}`, req).then((res) => res.data);

/**
 *
 * @param id {int} - Question Id
 * @param qUpdate {Object} - Object holding list of user ID's to notify
 * @returns {Promise<T>}
 */
const updateQuestionNotifications = (id, qUpdate) =>
  API.put(`${questionsAPI}${id}/notifications`, qUpdate).then((res) => res.data);

/**
 * @param {Object} params
 * @param {number|string} params.questionId
 * @param {string} [params.mode] - The insight list mode to return "" (Answer) "Reverse" (Trait)
 * @param {number|string | null} [params.targetId] - target id to filter results by
 * @param {string | null} [params.network] - network to filter results by
 * @param {string | null} [params.weightingSchemeName] - weighting scheme name to weight results
 * @param {string | null} [params.segmentUUID] - segment uuid to filter results by
 * @param {string | null} [params.valueGroupingUri] - optional value grouping to include in the request
 * @param {Date | null} [params.startDate] - start date of results to fetch
 * @param {Date | null} [params.endDate] - end date of results to fetch
 * @returns {QuestionInsightListResponse}
 */
const getQuestionInsightsList = ({
  questionId,
  mode,
  targetId,
  network,
  weightingSchemeName,
  segmentUUID,
  startDate,
  endDate,
  valueGroupingUri,
}) => {
  return API.get(`${questionsAPI}${questionId}/insights/list`, {
    params: {
      mode,
      targetId,
      network,
      wscheme: weightingSchemeName,
      segment: segmentUUID,
      startDate,
      endDate,
      valueGroupingUri,
    },
  }).then((res) => res.data);
};

/**
 *
 * @param {number|string} questionId
 * @param {string} mode - The narrative mode to return "Brief or Full"
 * @param {number|string} targetId - target id to filter results by
 * @param {string} wscheme - weighting scheme name to weight results
 * @param {string} segmentUUID - segment uuid to filter results by
 * @param {string} network - network uuid to filter results by
 * @param {string} startDate - start date of results to fetch
 * @param {string} endDate - end date of results to fetch
 * @returns {Promise<T>}
 */
const getQuestionInsightNarrative = ({
  questionId,
  mode,
  targetId,
  weightingSchemeName,
  segmentUUID,
  network,
  startDate,
  endDate,
}) => {
  return API.get(`${questionsAPI}${questionId}/insights/narrative`, {
    params: {
      mode,
      targetId,
      wscheme: weightingSchemeName,
      segment: segmentUUID,
      network,
      startDate,
      endDate,
    },
  }).then((res) => res.data);
};

/**
 * @typedef {Object} Details
 * @property {string} cd
 * @property {string} ct
 * @property {string} svg
 */

/**
 *
 * @param {number|string} questionId
 * @param {Details} details
 * @param {number|string} targetId - target id to filter results by
 * @param {string} wscheme - weighting scheme name to weight results
 * @param {string} segmentUUID - segment uuid to filter results by
 * @param {string} network - network code to filter results by
 * @param {string} startDate - start date of results to fetch
 * @param {string} endDate - end date of results to fetch
 */
const getQuestionInsightDetail = ({
  questionId,
  details,
  targetId,
  weightingSchemeName,
  segmentUUID,
  network,
  startDate,
  endDate,
}) => {
  return API.get(`${questionsAPI}${questionId}/insights/detail`, {
    params: {
      ...details,
      targetId,
      wscheme: weightingSchemeName,
      segment: segmentUUID,
      network,
      startDate,
      endDate,
    },
  }).then((res) => res.data);
};

/**
 *
 * @param {number|string} questionId
 * @param {number|string} targetId - target id to filter results by
 * @param {string | null} [network] - network to filter results by
 * @param {string} wscheme - weighting scheme name to weight results
 * @param {string} segmentUUID - segment uuid to filter results by
 * @param {string} startDate - start date of results to fetch
 * @param {string} endDate - end date of results to fetch
 */
const computeInsights = ({ questionId, targetId, network, weightingSchemeName, segmentUUID, startDate, endDate }) => {
  return API.get(`${questionsAPI}${questionId}/insights/compute`, {
    params: {
      targetId,
      network,
      wscheme: weightingSchemeName,
      segment: segmentUUID,
      startDate,
      endDate,
    },
  }).then((res) => res.data);
};

/**
 *
 * @param {object} params - Holds data for url query
 * @param {number|string} params.questionId - the id of the question
 * @param {number|string|null} params.targetId - target id to filter results by
 * @param {string|null} params.weightingSchemeName - weighting scheme name to weight results
 * @param {string|null} params.segmentUUID - segment uuid to filter results by
 * @param {Date|null} params.startDate - start date of results to fetch
 * @param {Date|null} params.endDate - end date of results to fetch
 * @param {string|null} params.valueGroupingUri - value grouping identifier.
 * @returns {Promise<T>}
 */
const getQuestionTimeView = ({
  questionId,
  targetId,
  weightingSchemeName,
  segmentUUID,
  network,
  startDate,
  endDate,
  valueGroupingUri,
}) => {
  return API.get(`${questionsAPI}${questionId}/c-results/timeview`, {
    params: {
      valueGroupingUri: valueGroupingUri || null,
      targetId: targetId || null,
      wscheme: weightingSchemeName || null,
      segment: segmentUUID || null,
      network,
      startDate,
      endDate: normalizeEndDate(startDate, endDate),
    },
  }).then((res) => res.data);
};

/**
 * @param {object} params
 * @param {number|string} params.questionId - the id of the question
 * @param {number|string|null} [params.targetId] - target id to filter results by
 * @param {string|null} [params.weightingSchemeName] - weighting scheme name to weight results
 * @param {string|null} [params.segmentUUID] - segment uuid to filter results by
 * @param {Date|null} [params.startDate] - start date of results to fetch
 * @param {Date|null} [params.endDate] - end date of results to fetch
 * @param {string} params.scoreUri
 * @returns {Promise<T>}
 */
const getQuestionScoreTimeview = ({
  questionId,
  targetId,
  weightingSchemeName,
  segmentUUID,
  network,
  startDate,
  endDate,
  scoreUri,
}) => {
  return API.get(`${questionsAPI}${questionId}/c-results/score/timeview`, {
    params: {
      scoreUri,
      targetId,
      wscheme: weightingSchemeName,
      segment: segmentUUID,
      network,
      startDate,
      endDate: normalizeEndDate(startDate, endDate),
    },
  }).then((res) => res.data);
};

/**
 * @typedef {Object} QuestionsCorrelatedSearchResultExtra
 * @property {number} tValue
 * @property {string} [compareDate]
 */

/**
 * @typedef {QuestionSearchResult & QuestionsCorrelatedSearchResultExtra} QuestionsCorrelatedSearchResult
 */

/**
 * @typedef SearchCorrelatedQuestionsResponse
 * @property {number} page
 * @property {number} pageTotal
 * @property {number} size
 * @property {number} total
 * @property {QuestionsCorrelatedSearchResult[]} results
 */

/**
 * @param {QuestionSearchCorrelatedArgs} params
 * @returns {Promise<SearchCorrelatedQuestionsResponse>}
 */
const searchCorrelatedQuestions = ({
  questionId,
  query,
  orderBy,
  order,
  tags,
  taxonomyTags,
  questionType,
  inputType,
  isMyFavoritesOnly,
  responseCountGte,
  isCurrentlyLive,
  isCyclical,
  searchAnswerOptions,
  isEngagement,
  isValue,
  isProfile,
  startDate,
  endDate,
  weightingScheme = null,
  page = 1,
  size = 10,
}) =>
  API.get(`${questionsAPI}${questionId}/correlated`, {
    params: {
      query,
      orderBy,
      order,
      inputType,
      tags,
      t: taxonomyTags,
      questionType,
      isMyFavoritesOnly,
      responseCountGte,
      isCurrentlyLive,
      isCyclical,
      searchAnswerOptions,
      isEngagement,
      isValue,
      isProfile,
      startDate: startDate ? format(startDate, DateFormats.Y_M_D) : null,
      endDate: endDate ? format(endDate, DateFormats.Y_M_D) : null,
      weightingScheme,
      page,
      size,
    },
  }).then((res) => res.data);

/**
 *
 * @param {object} params - Holds data for url query
 * @param {number|string} params.questionId
 * @param {string} params.map - 'country', 'metro', 'state'
 * @param {number|string} params.targetId - target id to filter results by
 * @param {string | null} [params.network] - network to filter results by
 * @param {string} params.weightingSchemeName - weighting scheme name to weight results
 * @param {string} params.segmentUUID - segment uuid to filter results by
 * @param {string} params.startDate - start date of results to fetch
 * @param {string} params.endDate - end date of results to fetch
 * @param {string|null} params.valueGroupingUri - value grouping to filter results by
 * @returns {Promise<T>}
 */
const getQuestionGeography = ({
  questionId,
  map,
  targetId,
  network,
  weightingSchemeName,
  segmentUUID,
  startDate,
  endDate,
  valueGroupingUri,
}) => {
  return API.get(`${questionsAPI}${questionId}/c-results/geo`, {
    params: {
      map,
      targetId,
      network,
      wscheme: weightingSchemeName,
      segment: segmentUUID,
      startDate,
      endDate: normalizeEndDate(startDate, endDate),
      valueGroupingUri,
    },
  }).then((res) => res.data);
};

/**
 *
 * @param {object} params - Holds data for url query
 * @param {number|string} params.questionId
 * @param {string} params.map - 'country', 'metro', 'state'
 * @param {string} params.scoreUri - score to filter results by
 * @param {number|string} params.targetId - target id to filter results by
 * @param {string | null} [params.network] - network to filter results by
 * @param {string} params.weightingSchemeName - weighting scheme name to weight results
 * @param {string} params.segmentUUID - segment uuid to filter results by
 * @param {string} params.startDate - start date of results to fetch
 * @param {string} params.endDate - end date of results to fetch
 * @returns {Promise<T>}
 */
const getQuestionGeographyScoreResults = ({
  questionId,
  map,
  scoreUri,
  targetId,
  network,
  weightingSchemeName,
  segmentUUID,
  startDate,
  endDate,
}) => {
  return API.get(`${questionsAPI}${questionId}/c-results/geo/score`, {
    params: {
      map,
      targetId,
      network,
      scoreUri,
      wscheme: weightingSchemeName,
      segment: segmentUUID,
      startDate,
      endDate: normalizeEndDate(startDate, endDate),
    },
  }).then((res) => res.data);
};

/**
 *
 * @param {number|string} questionId
 * @returns {Promise<T>}
 */
const favoriteQuestion = (questionId) => {
  return API.post(`${questionsAPI}${questionId}/favorite`).then((res) => res.data);
};

/**
 *
 * @param {number|string} questionId
 * @returns {Promise<T>}
 */
const unfavoriteQuestion = (questionId) => {
  return API.delete(`${questionsAPI}${questionId}/favorite`).then((res) => res.data);
};

/**
 * Does a user have access to the given question's Timeview.
 *
 * @param {number|string} questionId
 * @returns {Promise<import('types').QuestionTimeviewStatus>}
 */
const getQuestionTimeviewStatus = (questionId) => {
  return API.get(`${questionsAPI}${questionId}/timeview-status`).then((res) => res.data);
};

export default {
  create: createQuestion,
  createAnswerGrouping: createQuestionAnswerGrouping,
  createComment: createQuestionComment,
  createTag: createTagToQuestion,
  delete: deleteQuestion,
  deleteAnswerGrouping: deleteQuestionAnswerGrouping,
  deleteTag: deleteTagToQuestion,
  deployQuestion: deployQuestion,
  edit: editQuestion,
  get: getQuestion,
  getAnswerGrouping: getQuestionAnswerGrouping,
  getGeography: getQuestionGeography,
  getGeographyScore: getQuestionGeographyScoreResults,
  getResults: getQuestionResults,
  getQuestionCompareTableResults: getQuestionCompareTableResults,
  getQuestionCompareCheckboxResults: getQuestionCompareCheckboxResults,
  getTimeView: getQuestionTimeView,
  getTimeviewStatus: getQuestionTimeviewStatus,
  getScoreTimeview: getQuestionScoreTimeview,
  computeInsights: computeInsights,
  favorite: favoriteQuestion,
  getInsightList: getQuestionInsightsList,
  getInsightNarrative: getQuestionInsightNarrative,
  getInsightDetail: getQuestionInsightDetail,
  getProfileResults: getQuestionProfileResults,
  getScoreResults: getQuestionScoreResults,
  getValueGroupingResults: getQuestionValueGroupingResults,
  listAnswerChoices: getQuestionAnswerChoices,
  listAnswerGroupings: getQuestionAnswerGroupings,
  listComments: getQuestionComments,
  listDisplayOptions: getQuestionDisplayOptions,
  listDisplayGroupings: getQuestionDisplayGroupings,
  listDisplayScores: getQuestionDisplayScores,
  listNotifications: getQuestionNotifications,
  listQuestionPins: getQuestionPins,
  listQuestionSamples: getQuestionSamples,
  listTargets: getQuestionTargets,
  listScores: getQuestionScores,
  listTags: getQuestionTags,
  listTranslations: getQuestionTranslations,
  createTranslation: createQuestionTranslations,
  updateTranslation: updateQuestionTranslations,
  updateQuestionTargetReview: updateQuestionTargetReview,
  listTrending: getTrendingQuestions,
  search: searchQuestions,
  searchCorrelated: searchCorrelatedQuestions,
  stopQuestionPin: stopQuestionPin,
  unfavorite: unfavoriteQuestion,
  update: updateQuestion,
  updateAnswerGrouping: updateQuestionAnswerGrouping,
  updateNotifications: updateQuestionNotifications,
};
