const helpUrl = "https://support.civicscience.com/help";

const Help = {
  SupportEmail: "support@civicscience.com",
  HelpUrl: helpUrl,
  ContactUsUrl: helpUrl,
  QuestionInfoUrl: `${helpUrl}/introduction-to-questions`,
  QuestionSearchInfoUrl: `${helpUrl}/searching-for-questions`,
  QuestionCreateInfoUrl: `${helpUrl}/questions-2727b66-creating-a-question`,
  ScoresInfoUrl: `${helpUrl}/question-scores`,
  SegmentsInfoUrl: `${helpUrl}/introduction-to-segments`,
  ReportSearchHelpUrl: `${helpUrl}/introduction-to-reports`,
  DashboardInfoUrl: `${helpUrl}/introduction-to-dashboards`,
  TargetInfoUrl: `${helpUrl}/targets`,
  FeedInfoUrl: `${helpUrl}/feeds`,
  CrossTabInfoUrl: `${helpUrl}/introduction-to-crosstab-reports`,
  MultiScoreInfoUrl: `${helpUrl}/introduction-to-multiscore-reports`,
  MultiTimeViewInfoUrl: `${helpUrl}/introduction-to-multitimeview-reports`,
  DeepProfileInfoUrl: `${helpUrl}/introduction-to-deep-profile-reports`,
};
export default Help;
