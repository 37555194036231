import CSCancelButton from "components/shared/button/CSCancelButton";
import CSCategoryButton from "components/shared/button/CSCategoryButton";
import CSCloseButton from "components/shared/button/CSCloseButton";
import CSCreateButton from "components/shared/button/CSCreateButton";
import CSDangerButton from "components/shared/button/CSDangerButton";
import CSDeleteButton from "components/shared/button/CSDeleteButton";
import CSIconButton from "components/shared/button/CSIconButton";
import CSLinkButton from "components/shared/button/CSLinkButton";
import CSPrimaryButton from "components/shared/button/CSPrimaryButton";
import CSResetButton from "components/shared/button/CSResetButton";
import CSRevealButton from "components/shared/button/CSRevealButton";
import CSSecondaryButton from "components/shared/button/CSSecondaryButton";
import CSSecondaryButtonLink from "components/shared/button/CSSecondaryButtonLink";
import CSSidebarToggleButton from "components/shared/button/CSSidebarToggleButton";
import CSSubNavToggleButton from "components/shared/button/CSSubNavToggleButton";
import CSSubmitButton from "components/shared/button/CSSubmitButton";
import CSUpdateButton from "components/shared/button/CSUpdateButton";

/**
 * A collection of commonly used buttons.
 *
 * Typically, you will import this as `CSButton` as below
 *
 * @example
 *
 * import CSButton from 'components/shared/button/CSButton';
 *
 * <CSButton.Cancel onClick={onCancel} isSubmitting={formState.isSubmitting} />
 * <CSButton.Submit isSubmitting={formState.isSubmitting} />
 */
export default {
  Cancel: CSCancelButton,
  Category: CSCategoryButton,
  Close: CSCloseButton,
  Create: CSCreateButton,
  Danger: CSDangerButton,
  Delete: CSDeleteButton,
  Icon: CSIconButton,
  Link: CSLinkButton,
  Primary: CSPrimaryButton,
  Reset: CSResetButton,
  Reveal: CSRevealButton,
  Secondary: CSSecondaryButton,
  SecondaryLink: CSSecondaryButtonLink,
  Submit: CSSubmitButton,
  ToggleSidebar: CSSidebarToggleButton,
  ToggleSubNav: CSSubNavToggleButton,
  Update: CSUpdateButton,
};
