import {
  ProtectedRoute,
  defaultRoute,
  permissionProtectedRoutes,
  routePaths,
  unprotectedRoutes,
  useRouterScrollTop,
} from "routing";
import React, { Suspense, useRef } from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import AppFooter from "components/shared/footer/AppFooter";
import AppHeader from "components/shared/header/AppHeader";
import AppLeftNavbar from "components/shared/navigation/AppLeftNavbar";
import { Container } from "react-bootstrap";
import GlobalImpersonationForm from "components/shared/global/GlobalImpersonationForm";
import Loader from "components/shared/loader/Loader";
import NotFound from "components/shared/not-found/NotFound";
import classNames from "classnames";
import { envConfig } from "config";
import { useAuthState } from "context/AuthContext";
import { useLeftNavHelpers } from "utils";

const AuthenticatedApp = () => {
  const { publicUrl } = envConfig;
  const {
    currentUserIsImpersonated,
    currentUser: { permissions },
  } = useAuthState();
  const { navExpanded, onLeftNavToggle, onNavigate } = useLeftNavHelpers();

  const scrollTopRef = useRef<HTMLDivElement>(null);

  useRouterScrollTop({ isMountOnly: true, scrollNodeRef: scrollTopRef, pathname: "", canScroll: true });

  return (
    <Router basename={publicUrl}>
      <div className="csa-app csa-auth-app">
        <div className="csa-app-layout">
          <div className={classNames("csa-app-layout-left-nav", { expanded: navExpanded, collapsed: !navExpanded })}>
            <AppLeftNavbar
              navExpanded={navExpanded}
              onToggle={onLeftNavToggle}
              onNavigate={onNavigate}
              id="app-left-nav-bar"
            />
          </div>

          <div className="csa-app-layout-main-wrapper">
            <AppHeader />

            <div className="csa-app-layout-scroll" ref={scrollTopRef} tabIndex={-1}>
              {currentUserIsImpersonated && (
                <div className="csa-app-impersonation">
                  <GlobalImpersonationForm />
                </div>
              )}

              <main className="csa-app-main" tabIndex={-1}>
                <Suspense fallback={<Loader isAnimated={true} />}>
                  <Switch>
                    <Route exact path={routePaths.BASE}>
                      <Redirect to={defaultRoute.path} />
                    </Route>
                    {permissionProtectedRoutes(permissions).map((route, i) => (
                      <ProtectedRoute
                        key={`csa-auth-route_${i}`}
                        path={route.path}
                        component={route.component}
                        location=""
                      />
                    ))}
                    {unprotectedRoutes.map((route, i) => (
                      <Route path={route.path} key={`csa-unauth-route_${i}`}>
                        <Redirect to={defaultRoute.path} />
                      </Route>
                    ))}
                    <Route>
                      <Container fluid={true}>
                        <NotFound />
                      </Container>
                    </Route>
                  </Switch>
                </Suspense>
              </main>

              <AppFooter className="container-fluid shadow-inset shadow-sm" />
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default AuthenticatedApp;
