import {
  isDesktopScreenWidth,
  isMobileScreenWidth,
  isNotebookScreenWidth,
  isTVScreenWidth,
  isTabletScreenWidth,
} from "utils";

import { useLayoutEffect, useState } from "react";

type useAdaptiveHelpersReturn = {
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isNotebook: boolean;
  isTV: boolean;
  innerWidth: number;
};

const getAdaptiveState = () => {
  return {
    isDesktop: isDesktopScreenWidth(),
    isMobile: isMobileScreenWidth(),
    isTablet: isTabletScreenWidth(),
    isNotebook: isNotebookScreenWidth(),
    isTV: isTVScreenWidth(),
  };
};

const useAdaptiveHelpers = (): useAdaptiveHelpersReturn => {
  const [adaptiveAttributes, setAdaptiveAttributes] = useState(getAdaptiveState());
  const listenerType = "resize";

  const onWindowResize = () => setAdaptiveAttributes(getAdaptiveState());

  useLayoutEffect(() => {
    window.addEventListener(listenerType, onWindowResize);

    return () => window.removeEventListener(listenerType, onWindowResize);
  }, []);

  return {
    innerWidth: window.innerWidth,
    ...adaptiveAttributes,
  };
};

export default useAdaptiveHelpers;
