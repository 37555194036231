import "App.scss";

import AppErrorBoundaryView, { onAppBoundaryError } from "components/shared/error/AppErrorBoundaryView";
import { Children, initializeIntercom, isNodeEnvTest, pushToDataLayer } from "utils";
import { QueryClient, QueryClientProvider } from "react-query";
import React, { useEffect } from "react";

import AuthProvider from "context/AuthContext";
import AuthenticatedApp from "components/AuthenticatedApp";
import Bugsnag from "@bugsnag/js";
import Loader from "components/shared/loader/Loader";
import UnAuthenticatedApp from "components/UnAuthenticatedApp";
import { envConfig } from "config";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      retry: false,
    },
  },
});

const AppTestErrorBoundary = ({ children }) => {
  return children;
};
AppTestErrorBoundary.propTypes /* remove-proptypes */ = {
  children: Children,
};

const App = () => {
  const AppErrorBoundary = isNodeEnvTest()
    ? AppTestErrorBoundary
    : Bugsnag.getPlugin("react").createErrorBoundary(React);

  // GTM entry pageview
  useEffect(() => {
    pushToDataLayer({ event: "pageview" });
  }, []);

  !window.Intercom && !isNodeEnvTest() && initializeIntercom(envConfig.intercomAppId);

  return (
    <AppErrorBoundary FallbackComponent={AppErrorBoundaryView} onError={onAppBoundaryError}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider
          whenLoading={() => <Loader loaderMessage="" isAnimated={true} />}
          whenUnAuthenticated={() => <UnAuthenticatedApp />}
          whenAuthenticated={() => <AuthenticatedApp />}
        />
      </QueryClientProvider>
    </AppErrorBoundary>
  );
};

export default App;
