import "fonts.js";
import "index.scss";

import App from "App";
import { BugsnagUtils } from "utils";
import React from "react";
import ReactDOM from "react-dom";

BugsnagUtils.initialize();

const AppToRender = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

ReactDOM.render(AppToRender, document.getElementById("root"));
