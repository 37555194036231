import {
  CheckboxQuestionCompareCrosstabResponse,
  DisplayOptionsForQuestion,
  GetQuestionCompareCheckboxResultsArgs,
  MultiScoreResults,
  MultiScoreResultsArgs,
  QuestionAnswerChoice,
  QuestionDisplayOption,
  QuestionEditRequest,
  QuestionFull,
  QuestionInsightListResponse,
  QuestionResultsFilters,
  QuestionSearchArgs,
  QuestionTimeviewStatus,
  SearchQuestionsResponse,
} from "types";
import { QueryClient, UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from "react-query";
import { cacheKeyForDateRange, defaultChartQueryOptions } from "utils";

import { QuestionsAPI } from "api";

type QuestionInsightsListArgs = Parameters<typeof QuestionsAPI.getInsightList>[0];

type useQuestionCompareCheckboxResultsArgs = {
  qid: string | number;
  checkboxGroupId: string | number;
  filters: QuestionResultsFilters;
};

export const queryKeys = {
  all: () => ["questions"],
  details: () => [...queryKeys.all(), "detail"] as const,
  detail: (id: number | string) => [...queryKeys.details(), id] as const,
  answerChoices: (id: number | string) => [...queryKeys.detail(id), "answer-choices"] as const,
  answerDisplayGroupings: (id: number | string) => [...queryKeys.detail(id), "answer-display-groupings"] as const,
  scores: (id: number | string) => [...queryKeys.detail(id), "scores"],
  answerDisplayOptions: (id: number | string) => [...queryKeys.detail(id), "display-options"] as const,
  scoreResults: ({ questionId, startDate, endDate, ...args }: MultiScoreResultsArgs) => [
    ...queryKeys.detail(questionId),
    "score-results",
    {
      ...args,
      ...cacheKeyForDateRange({ startDate, endDate }),
    },
  ],
  timeviewStatus: (id: number | string) => [...queryKeys.detail(id), "timeview-status"],
  insightsList: ({ questionId, startDate = null, endDate = null, ...args }: QuestionInsightsListArgs) => [
    ...queryKeys.detail(questionId),
    "question-insights",
    {
      mode: args.mode,
      segmentUUID: args.segmentUUID,
      targetId: args.targetId,
      network: args.network,
      valueGroupingUri: args.valueGroupingUri,
      weightingSchemeName: args.weightingSchemeName,
      ...cacheKeyForDateRange({ startDate, endDate }),
    },
  ],
  searchBase: () => ["question-search"],
  search: ({ startDate = null, endDate = null, ...params }: QuestionSearchArgs) => [
    ...queryKeys.searchBase(),
    {
      searchAnswerOptions: params.searchAnswerOptions,
      includeScores: params.includeScores,
      inputType: params.inputType,
      isArchived: params.isArchived,
      isCurrentlyLive: params.isCurrentlyLive,
      isCyclical: params.isCyclical,
      isEngagement: params.isEngagement,
      isMyFavoritesOnly: params.isMyFavoritesOnly,
      isProfile: params.isProfile,
      isTracking: params.isTracking,
      isTrending: params.isTrending,
      isValue: params.isValue,
      order: params.order,
      orderBy: params.orderBy,
      page: params.page,
      query: params.query,
      questionType: params.questionType,
      responseCountGte: params.responseCountGte,
      size: params.size,
      tags: params.tags,
      taxonomyTags: params.taxonomyTags,
      ...cacheKeyForDateRange({ startDate, endDate }),
    },
  ],
};

/**
 * Fetches the answer choices for a question.
 */
export const useQuestionAnswerChoices = <T = QuestionAnswerChoice[]>(
  id: number | string,
  options: UseQueryOptions<QuestionAnswerChoice[], unknown, T> = {},
) =>
  useQuery<QuestionAnswerChoice[], unknown, T>(
    queryKeys.answerChoices(id),
    () => QuestionsAPI.listAnswerChoices(id),
    options,
  );

/**
 * Fetches all display groupings for a question.
 */
export const useQuestionAnswerDisplayGroupings = <T = QuestionDisplayOption[]>(
  id: number | string,
  options: UseQueryOptions<QuestionDisplayOption[], unknown, T> = {},
) =>
  useQuery<QuestionDisplayOption[], unknown, T>(
    queryKeys.answerDisplayGroupings(id),
    () => QuestionsAPI.listDisplayGroupings(id),
    options,
  );

/**
 * Fetches display options for question scores.
 */
export const useQuestionScores = <T = QuestionDisplayOption[]>(
  id: number | string,
  options: UseQueryOptions<QuestionDisplayOption[], unknown, T> = {},
) =>
  useQuery<QuestionDisplayOption[], unknown, T>(
    queryKeys.scores(id),
    () => QuestionsAPI.listDisplayScores(id),
    options,
  );

/**
 * Fetches question insights based on the provided params.
 */
export const useQuestionInsightsList = <T = QuestionInsightListResponse>(
  args: QuestionInsightsListArgs,
  options: UseQueryOptions<QuestionInsightListResponse, unknown, T> = {},
) =>
  useQuery<QuestionInsightListResponse, unknown, T>(
    queryKeys.insightsList(args),
    () => QuestionsAPI.getInsightList(args),
    options,
  );

/**
 * Performs a questions search using the provided params.
 */
export const useQuestionSearch = <T = SearchQuestionsResponse>(
  args: QuestionSearchArgs,
  options: UseQueryOptions<SearchQuestionsResponse, unknown, T> = {},
) => useQuery<SearchQuestionsResponse, unknown, T>(queryKeys.search(args), () => QuestionsAPI.search(args), options);

/**
 * Fetches timeview availability for a given question.
 */
export const useQuestionTimeviewStatus = <T = QuestionTimeviewStatus>(
  questionId: number | string,
  options: UseQueryOptions<QuestionTimeviewStatus, unknown, T> = {},
) =>
  useQuery<QuestionTimeviewStatus, unknown, T>(
    queryKeys.timeviewStatus(questionId),
    () => QuestionsAPI.getTimeviewStatus(questionId),
    options,
  );

/**
 * Fetch Score Results for chart data Timeview with Top Level Data.
 */
export const useQuestionScoreResults = <T = MultiScoreResults>(
  args: MultiScoreResultsArgs,
  options: UseQueryOptions<MultiScoreResults, unknown, T> = {},
) =>
  useQuery<MultiScoreResults, unknown, T>(
    queryKeys.scoreResults(args),
    () => QuestionsAPI.getScoreResults(args),
    options,
  );

/**
 * Fetch Results for checkbox question compare.
 */
export const useQuestionCompareCheckboxResults = (
  { qid, checkboxGroupId, filters: { segmentUUID, ...restFilters } }: useQuestionCompareCheckboxResultsArgs,
  options?: UseQueryOptions<CheckboxQuestionCompareCrosstabResponse>,
) => {
  const apiParams: GetQuestionCompareCheckboxResultsArgs = {
    ...restFilters,
    segments: [segmentUUID],
    qid,
    checkboxGroupId,
  };

  const cacheParams = {
    ...apiParams,
    ...cacheKeyForDateRange({ startDate: restFilters.startDate, endDate: restFilters.endDate }),
  };

  return useQuery<CheckboxQuestionCompareCrosstabResponse>(
    ["checkboxQuestionCompare", cacheParams],
    () => QuestionsAPI.getQuestionCompareCheckboxResults(apiParams),
    {
      ...defaultChartQueryOptions,
      ...options,
    },
  );
};

/**
 * Fetch a question.
 */
export const useQuestion = <T extends QuestionFull>(id: number, options: UseQueryOptions<QuestionFull, unknown, T>) =>
  useQuery<QuestionFull, unknown, T>(queryKeys.detail(id), () => QuestionsAPI.get(id, true), options);

/**
 * Edit a question.
 */
export const useEditQuestion = (options: UseMutationOptions<QuestionFull, unknown, QuestionEditRequest>) => {
  const queryClient = useQueryClient();
  return useMutation<QuestionFull, unknown, QuestionEditRequest>((args) => QuestionsAPI.edit(args), {
    // Update Query Cache & given onSuccess function
    onSuccess: (...args) => {
      queryClient.setQueryData(queryKeys.detail(args[0].questionId), args[0]);
      !!options.onSuccess && options.onSuccess(...args);
    },
  });
};

/**
 * Invalidate the display options query for a question.
 * To be used after a mutation may have changed or created
 * the available options.
 */
export const invalidateDisplayOptions = (queryClient: QueryClient, questionId: number) =>
  queryClient.invalidateQueries({
    queryKey: queryKeys.answerDisplayOptions(questionId),
    refetchInactive: true,
  });

/**
 * Fetch display options for a question.
 */
export const useQuestionDisplayOptions = <T = DisplayOptionsForQuestion>(
  id: number | string,
  options: UseQueryOptions<DisplayOptionsForQuestion, unknown, T> = {},
) =>
  useQuery<DisplayOptionsForQuestion, unknown, T>(
    queryKeys.answerDisplayOptions(id),
    () => QuestionsAPI.listDisplayOptions(id),
    options,
  );
