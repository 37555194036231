const csApi = "/api/1";
const authApi = `${csApi}/auth`;

const envConfig = {
  insightStoreUrl: import.meta.env.VITE_INSIGHT_STORE_URL,
  insightStore2Url: import.meta.env.VITE_INSIGHT_STORE_2_URL,
  authApi,
  authTokenAPI: `${authApi}/token`,
  authTokenRefreshAPI: `${authApi}/token/refresh`,
  authLogoutAPI: `${authApi}/logout`,
  authPasswordAPI: `${authApi}/password/`,
  authContractAPI: `${authApi}/contract`,
  authRegistrationAPI: `${authApi}/registration/`,
  csApi,
  targetsAPI: `${csApi}/targets/`,
  weightingSchemesAPI: `${csApi}/weighting-schemes/`,
  questionsAPI: `${csApi}/questions/`,
  questionFeedsAPI: `${csApi}/question-feeds/`,
  questionSamplesAPI: `${csApi}/question-samples/`,
  matrixQuestionsAPI: `${csApi}/matrix-questions/`,
  segmentsAPI: `${csApi}/segments/`,
  deploymentSchemesAPI: `${csApi}/deployment-schemes/`,
  dashboardsAPI: `${csApi}/dashboards/`,
  reportsAPI: `${csApi}/reports/`,
  profileAPI: `${csApi}/profile/`,
  accountsAPI: `${csApi}/accounts/`,
  networksAPI: `${csApi}/networks/`,
  notificationsAPI: `${csApi}/notifications/`,
  userGroupsAPI: `${csApi}/usergroups/`,
  scoreTablesAPI: `${csApi}/score-tables/`,
  checkboxGroupsAPI: `${csApi}/checkbox-groups/`,
  shareableArtifactsAPI: `${csApi}/sharing/`,
  deepProfilesAPI: `${csApi}/reports/deep-profiles/`,
  multiScoresAPI: `${csApi}/reports/multi-scores/`,
  multiTimeviewsAPI: `${csApi}/reports/multi-attribute-timeviews/`,
  scoresAPI: `${csApi}/scores/`,
  tagsAPI: `${csApi}/tags/`,
  taxonomyAPI: `${csApi}/taxonomy/`,
  sysadminSolrAPI: `${csApi}/sysadmin-solr/`,
  nuggetsAPI: `${csApi}/nuggets/`,
  emailAPI: `${csApi}/email/`,
  emailableArtifactsAPI: `${csApi}/emailable-artifacts/`,
  starRatingQuestionsAPI: `${csApi}/star-rating-questions/`,
  marketingAPI: `${csApi}/marketing/`,
  crosstabAPI: `${csApi}/crosstabs/`,
  apiTimeoutMS: import.meta.env.VITE_API_TIMEOUT,
  isDebugMode: import.meta.env.VITE_DEBUG,
  publicUrl: import.meta.env.PUBLIC_URL,
  intercomAppId: import.meta.env.VITE_INTERCOM_APP_ID,
  cdnHostName: import.meta.env.VITE_CDN_HOSTNAME,
  podcastUrl: "https://open.spotify.com/show/2C4b5DBB81mUtHuXBGkzGN",
  marketingWeeklyEmailsUrl: "https://civicscience.com/wws-archive/",
  marketingLatestInsightsUrl: "https://civicscience.com/latest-insights/",
  nodeEnv: process.env.NODE_ENV,
  appEnv: import.meta.env.VITE_ENV,
  appVersion: import.meta.env.VITE_VERSION,
  bugsnagApiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  bugsnagReleaseStage: import.meta.env.VITE_BUGSNAG_RELEASE_STAGE,
  trendingTagId: import.meta.env.VITE_TRENDING_TAG_ID,
  genderCombinedAnswerGrouping: import.meta.env.VITE_GENDER_COMBINED_ANSWER_GROUPING,
  profileCheckboxRaceQuestion: import.meta.env.VITE_PROFILE_CHECKBOX_RACE_QUESTION,
  audienceLensUrl: import.meta.env.VITE_AUDIENCE_LENS_URL,
  sageUrl: import.meta.env.VITE_SAGE_URL,
};

export default envConfig;
