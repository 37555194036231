import { CSApiError, humps, isArray, isString, toObject } from "@civicscience/chops";

import { AuthAPI } from "api";
import { authUtils } from "utils";
import axios from "axios";
import { envConfig } from "config";
import { stringify } from "qs";

/**
 * @typedef { import('types').AuthData } AuthData
 */

const { authTokenAPI, authTokenRefreshAPI } = envConfig;

const API = axios.create({
  // All API requests go to the same hostname that
  // the frontend is being hosted from.
  // This allows us to use setupProxy.js for local development.
  transformRequest: [(data) => humps.decamelizeKeys(data), ...axios.defaults.transformRequest],
  transformResponse: [...axios.defaults.transformResponse, (data) => humps.camelizeKeys(data)],
  /**
   * See SOF-1926 and https://github.com/CivicScience/core/pull/518 for more discussion.
   * Our Python API expects an array to be serialized to a query string as repeated keys:
   * `tags=brand&tags=retail&tags=news`
   *
   * Axios is close to this, but includes brackets which our API will not accept:
   * `tags[]=brand&tags[]=retail&tags[]=news`
   *
   * Axios does not provide "config" to change the behavior of the built-in serializer.
   * Instead, you replace the serializer as a whole with another lib - which we've done here.
   *
   * The key pieces for our API are
   * - Array formatting mentioned above
   * - Do NOT include keys that have a `null` or `undefined` value
   * - Serialize dates in ISO format => '2022-07-01T04:00:00.000Z'
   */
  paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat", skipNulls: true }),
});

const apiAuthState = {
  accessToken: null,
  expires: null,
  expireTime: null,
};

/**
 * Intended to be called whenever new `AuthData` is returned from the API.
 * The primary use cases are
 *
 * 1. When the app initially loads and the "refresh" endpoint successfully returns new AuthData
 * 2. When a user successfully logs in via username and password and the API returns new AuthData.
 *
 * @param {AuthData} authData
 */
export const setApiAuthState = (authData) => {
  const { accessToken, expiresIn } = authData;
  const nowTime = Date.now();
  const expireTime = nowTime + expiresIn;

  apiAuthState.accessToken = accessToken;
  apiAuthState.expires = expiresIn;
  apiAuthState.expireTime = expireTime;
};

/**
 * Intended to be called whenever a user logs out of the application.
 * Clears all existing AuthData.
 */
export const clearApiAuthState = () => {
  apiAuthState.accessToken = null;
  apiAuthState.expires = null;
  apiAuthState.expireTime = null;
};

API.interceptors.request.use(
  (config) => {
    // Edit request config
    const newConfig = { ...config };

    // we could choose to refresh before a request if expired/expiring,
    // or after (see response interceptor below)

    if (apiAuthState.accessToken) {
      newConfig.headers["Authorization"] = `Bearer ${apiAuthState.accessToken}`;
    }

    if (config.params) {
      newConfig.params = humps.decamelizeKeys(config.params);
    }

    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  (response) => {
    // Edit response config
    return response;
  },
  (error) => {
    //FIXME: @errorCode will be a custom error code,
    // we will need to figure out where in the response errorCode exists.
    const { config, response } = toObject(error);
    const { status, statusText, errorCode = "", data } = toObject(response);

    // Return any error for the login (token) service, or is a non-401
    if (config.url === `${authTokenAPI}` || status !== 401) {
      if (status >= 500 && status < 600) {
        //FIXME: general catch-all for unknown error messages for july 2022 demo release
        //TODO: future refactors of CSApiError to potentially have message vs detailedMessage
        // and/or many other changes we don't have time for right now - SOF-611
        return Promise.reject(new CSApiError(status, statusText, errorCode, "An unknown error occurred."));
      } else {
        //FIXME: This whole block could be a shared apiErrorHandler method.
        if (data && data.detail) {
          // Join error messages together separated by newline
          if (isArray(data.detail)) {
            const message = data.detail
              .map((item) => {
                const { msg = "" } = toObject(item);
                return isString(item) ? item : msg;
              })
              .join("\n");
            return Promise.reject(new CSApiError(status, statusText, errorCode, message));
          }
          //data.detail string case
          return Promise.reject(new CSApiError(status, statusText, errorCode, data.detail));
        }

        // NOTE: This handles the case where we have a non 500 error from the API
        // but we were not provided a "detail" property - either missing or empty string.
        // If we want to show a "generic" message in these cases we would do it here.
        const genericUnknownError = "Sorry, there was an error. Please try again.";
        return Promise.reject(new CSApiError(status, statusText, errorCode, genericUnknownError));
      }
    }

    // Logout user if token refresh didn't work
    if (config.url === `${authTokenRefreshAPI}`) {
      //clear token via dict
      clearApiAuthState();

      return new Promise((resolve, reject) => {
        if (authUtils.canRedirectToLoginFrom401()) {
          window.location.href = authUtils.getLoggedOutUrl();
        } else {
          return reject(new CSApiError(status, statusText, errorCode, error));
        }
      });
    }

    // Try to refresh token
    return AuthAPI.refreshToken()
      .then((authData) => {
        //On success, store token then re-issue request with new token
        setApiAuthState(authData);

        return new Promise((resolve, reject) => {
          return API.request(config)
            .then((response) => resolve(response))
            .catch((error) => reject(new CSApiError(status, statusText, errorCode, error)));
        });
      })
      .catch((error) => Promise.reject(new CSApiError(status, statusText, errorCode, error)));
  },
);

export default API;
