export const ResponsiveBreakPoints = {
  MobileSm: [0, 361],
  Mobile: [0, 572],
  TabletSm: [572, 813],
  TabletLg: [813, 1081],
  Tablet: [572, 1081],
  Notebook: [1081, 1441],
  NotebookLg: [1201, 1441],
  Desktop: [1441, 1921],
  TV: [1921],
} as const;
