import { Account, User } from "types";
import { bootIntercomUser, pushToDataLayer } from "utils";

import { envConfig } from "config";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";

const { intercomAppId = "" } = envConfig;

type BootAnalyticsUser = {
  currentUser: User;
  account: Account;
};

/**
 * Send user specific initialization to analytics platforms
 */
const bootAnalytics = ({ currentUser: user, account }: BootAnalyticsUser): void => {
  const firstName = user.firstName ?? "";
  const lastName = user.lastName ?? "";
  const userFullName = firstName.concat(" ").concat(lastName);
  const { userId, accountRefid: accountId, userInfo, isImpersonated, isSysAdmin, isInternal } = user;
  const { email } = userInfo;

  // Given intercom is our support system sending email, name, etc IS safe here
  !isNullOrUndefOrEmpty(intercomAppId) &&
    bootIntercomUser({
      appId: intercomAppId,
      userFullName,
      userId,
      email,
      isSysAdmin,
      accountId,
      isPublisherAccount: account.isPublisher,
    });

  pushToDataLayer({ userId, accountId, isImpersonated, isSysAdmin, isInternal });
};

export default bootAnalytics;
